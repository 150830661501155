// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-xops-net-gatsby-openrpc-theme-src-templates-default-tsx": () => import("./../../../node_modules/@xops.net/gatsby-openrpc-theme/src/templates/default.tsx" /* webpackChunkName: "component---node-modules-xops-net-gatsby-openrpc-theme-src-templates-default-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-documentation-tsx": () => import("./../../../src/pages/api-documentation.tsx" /* webpackChunkName: "component---src-pages-api-documentation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

